import { DashboardOutlined, FileDoneOutlined, LineChartOutlined, ScheduleOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const navDashboardTab = [
	{
		key: 'dashboard',
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: 'Dashboard',
		icon: DashboardOutlined,
		breadcrumb: false,
		isGroupTitle: true,
		submenu: [],
	},
];

const navReportsTab = [
	{
		key: 'reports',
		path: `${APP_PREFIX_PATH}/reports`,
		title: 'Reports',
		icon: FileDoneOutlined,
		breadcrumb: false,
		isGroupTitle: true,
		submenu: [],
	},
];
const navKpiTab = [
	{
		key: 'kpi',
		path: `${APP_PREFIX_PATH}/kpi`,
		title: 'KPI',
		icon: LineChartOutlined,
		breadcrumb: false,
		isGroupTitle: true,
		submenu: [],
	},
];
const navSurveyTab = [
	{
		key: 'survey',
		path: `${APP_PREFIX_PATH}/survey`,
		title: 'Survey',
		icon: ScheduleOutlined,
		breadcrumb: false,
		isGroupTitle: true,
		submenu: [],
	},
];

const navEducationTab = [
	{
		key: 'education',
		path: `${APP_PREFIX_PATH}/education`,
		title: 'Add Education',
		icon: ScheduleOutlined,
		breadcrumb: false,
		isGroupTitle: true,
		submenu: [],
	},
];

const navRegisterTab = [
	{
		key: 'registers',
		path: `${APP_PREFIX_PATH}/registers`,
		title: 'Registers',
		icon: ScheduleOutlined,
		breadcrumb: false,
		isGroupTitle: true,
		submenu: [],
	},
];

const navigationConfig = [...navDashboardTab, ...navReportsTab, ...navRegisterTab, ...navEducationTab, ...navKpiTab, ...navSurveyTab];

export default navigationConfig;
